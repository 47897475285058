import { extractProperty } from "./extractProperty"

export type CountryItem = {
  title?: string
  items: {
    icon: any
    selectColor: any
    text: any
    number: any
  }[]
  $right?: string
  $top?: string
  $left?: string
  $lgRight?: string
  $lgLeft?: string
  delayTimeMultiply: number
}

export function getCountryItems({ data }: { data: any }) {
  const object = data[0]
  const soloTitle = extractProperty(object, "SolesRent") as string | undefined
  const soloItemsArray = (extractProperty(object, "mapItemG") as {}[]) || []
  const soloItems = soloItemsArray.map((el) => ({
    icon: extractProperty(el, "icon"),
    selectColor: extractProperty(el, "selectColor"),
    text: extractProperty(el, "text"),
    number: extractProperty(el, "number"),
  }))
  const whitethornTitle = extractProperty(object, "inOperation") as string | undefined
  const whitethornItemsArray = (extractProperty(object, "mapItemsWhitethorn") as {}[]) || []
  const whitethornItems = whitethornItemsArray.map((el) => ({
    icon: extractProperty(el, "icon"),
    selectColor: extractProperty(el, "selectColor"),
    text: extractProperty(el, "text"),
    number: extractProperty(el, "number"),
  }))
  const rollingSunTitle = extractProperty(object, "rollingSun") as string | undefined
  const rollingSunItemsArray = (extractProperty(object, "mapItemRollingSun") as {}[]) || []
  const rollingSunItems = rollingSunItemsArray.map((el) => ({
    icon: extractProperty(el, "icon"),
    selectColor: extractProperty(el, "selectColor"),
    text: extractProperty(el, "text"),
    number: extractProperty(el, "number"),
  }))
  const tierraBonitaTitle = extractProperty(object, "tierraBonitaTX") as string | undefined
  const tierraBonitaItemsArray = (extractProperty(object, "mapItemsTierraBonita") as {}[]) || []
  const tierraBonitaItems = tierraBonitaItemsArray.map((el) => ({
    icon: extractProperty(el, "icon"),
    selectColor: extractProperty(el, "selectColor"),
    text: extractProperty(el, "text"),
    number: extractProperty(el, "number"),
  }))
  const patrimonTitle = extractProperty(object, "elPatrimonioTX") as string | undefined
  const patrimonItemsArray = (extractProperty(object, "mapItemsElPatrimonio") as {}[]) || []
  const patrimonItems = patrimonItemsArray.map((el) => ({
    icon: extractProperty(el, "icon"),
    selectColor: extractProperty(el, "selectColor"),
    text: extractProperty(el, "text"),
    number: extractProperty(el, "number"),
  }))
  const larreaTXTitle = extractProperty(object, "larreaTX") as string | undefined
  const larreaTXItemsArray = (extractProperty(object, "mapItemsLarreaTX") as {}[]) || []
  const larreaTXItems = larreaTXItemsArray.map((el) => ({
    icon: extractProperty(el, "icon"),
    selectColor: extractProperty(el, "selectColor"),
    text: extractProperty(el, "text"),
    number: extractProperty(el, "number"),
  }))
  const array: CountryItem[] = [
    {
      title: soloTitle,
      items: soloItems,
      $right: "0%",
      $lgRight: "6%",
      delayTimeMultiply: 1,
    },
    {
      title: tierraBonitaTitle,
      items: tierraBonitaItems,
      $left: "0%",
      $top: "39%",
      $lgLeft: "6%",
      delayTimeMultiply: 2,
    },
    {
      title: whitethornTitle,
      items: whitethornItems,
      $right: "0%",
      $top: "82%",
      $lgRight: "6%",
      delayTimeMultiply: 3,
    },
    {
      title: rollingSunTitle,
      items: rollingSunItems,
      $left: "0%",
      $lgLeft: "6%",
      delayTimeMultiply: 1,
    },
    {
      title: patrimonTitle,
      items: patrimonItems,
      $left: "0%",
      $top: "82%",
      $lgLeft: "6%",
      delayTimeMultiply: 3,
    },
    {
      title: larreaTXTitle,
      items: larreaTXItems,
      $right: "0%",
      $top: "39%",
      $lgRight: "6%",
      delayTimeMultiply: 2,
    },
  ]
  return array
}
